
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import store from "@/store";
import { ErrorMessage, Field, Form } from "vee-validate";
import SettlementOfferTable from "@/components/reusable/claims/SettlementOfferTable.vue";

export default defineComponent({
  name: "add-motor-insurance-claims-settlement-offer",
  components: {
    ErrorMessage,
    Field,
    Form,
    SettlementOfferTable,
  },
  data() {
    return {
      publicId: this.$route.params.publicId,
      claim: {},
      offerDocument: "",
      amount: "",
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const saveSettlementOffer = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();
            formData.append("amount", values["amount"]);
            formData.append("claimPublicId", values["claimPublicId"]);
            formData.append(
              "offerDocument",
              values["offerDocument"] as string | Blob
            );

            ApiService.setHeader();
            ApiService.post("/settlement", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Settlement offer has been sent",
                  "success"
                );
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "broker-client-claims-listing",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settlement Offer", [
        "Claims",
        "Motor Insurance Claim",
      ]);
    });

    return { submitButton1, saveSettlementOffer, user };
  },
  created() {
    this.getClaimInformation();

    //Set page title
    document.title =
      "Motor Insurance Claim: Settlement Offer | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}/${this.publicId}`
        )
          .then(({ data }) => {
            //Assign data to variables
            this.claim = data.data;

            if (data.data.isSettlementOfferAccepted) {
              variables.toastAlert(
                "Claim settlement offer has already been accepted",
                "error"
              );
              router.push({
                name: "broker-client-claims-listing",
              });
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(event) {
      return variables.numberFormatter(event.target.id);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    offerDocumentSelected(event) {
      this.offerDocument = event.target.files[0];
    },
  },
});
